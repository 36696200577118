<script setup>
const props = defineProps(['isHomepage', 'large']);

import { useCommonStore } from '../stores/common';
const store = useCommonStore();
const route = useRoute();
const localePath = useLocalePath();
const variant = ref(null);
watchEffect(() => {
  let x = store;
  if (route.path == localePath('index')) {
    variant.value = store.getTypeVariant || 'black';
  } else {
    variant.value = 'black';
  }
});

const menuActive = computed(() => store.mobileMenuOpen);
const headerFixed = computed(() => store.headerFixed);

const { toggleMobileMenu } = store;

const prevScrollpos = ref(0);
const isHidden = ref(false);

onMounted(() => {
  prevScrollpos.value = window.scrollY;
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});

const handleScroll = () => {
  const currentScrollPos = window.scrollY;
  if (headerFixed.value || props.isHomepage || currentScrollPos.value < 65 || prevScrollpos.value > currentScrollPos) {
    isHidden.value = false;
  } else {
    isHidden.value = true;
  }
  prevScrollpos.value = currentScrollPos;
};
</script>

<template>
  <header
    class="mobile-header"
    :class="{
      'mobile-header--hp': props.isHomepage,
      'mobile-header--hidden': isHidden,
    }"
  >
    <ClientOnly>
      <nuxt-link :to="localePath('index')" class="mobile-header__logo">
        <img class="mobile-header__logo-elem" :src="`/img/logo-${variant}.svg`" :alt="$t('global.company-full-name')" />
      </nuxt-link>

      <div class="mobile-header__hamburger">
        <button class="hamburger hamburger--squeeze" :class="[{ 'is-active': menuActive }, `hamburger--${variant || 'black'}`]" type="button" @click="toggleMobileMenu" @keyup.enter="toggleMobileMenu">
          <span class="hamburger-box">
            <span class="hamburger-inner"
              ><span class="hidden">{{ $t('global.menu') }}</span></span
            >
          </span>
        </button>
      </div>
    </ClientOnly>
  </header>
</template>

<style lang="scss" scoped>
$hamburger-types: (squeeze) !default;
$hamburger-layer-width: 20px !default;
$hamburger-layer-height: 2px !default;
$hamburger-layer-spacing: 5px !default;
$hamburger-padding-x: 10px !default;
$hamburger-padding-y: 10px !default;
$hamburger-layer-border-radius: 0px !default;
$hamburger-position: relative !default;
$hamburger-right: -10px !default;
$hamburger-top: -10px !default;

.mobile-header {
  $s: &;
  background: $white;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 20px;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: 300ms transform;
  width: 100%;
  z-index: 10;

  &--hp {
    background: transparent;
    position: absolute;

    #{$s}__logo {
      height: calc(100vw - 40px);
      pointer-events: none;
    }

    #{$s}__logo-elem {
      margin-top: -2px;
    }
  }

  &--hidden {
    transform: translateY(-100%);
  }

  @include mq($lg) {
    display: none;
  }

  &__logo {
    height: 70px;
    pointer-events: all;
  }

  &__logo-elem {
    height: 100%;
  }

  &__hamburger {
    pointer-events: all;
  }
}

.hamburger {
  padding: 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  &--white .hamburger-inner,
  &--white .hamburger-inner::before,
  &--white .hamburger-inner::after {
    background-color: white;
  }
  &--border .hamburger-inner,
  &--border .hamburger-inner::before,
  &--border .hamburger-inner::after {
    background-color: white;
  }
}
.hamburger:hover {
  opacity: 0.7;
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
  content: '';
  display: block;
}
.hamburger-inner::before {
  top: -7px;
}
.hamburger-inner::after {
  bottom: -7px;
}

/*
 * Squeeze
 */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner::before {
  transition:
    top 0.1s 0.14s ease,
    opacity 0.1s ease;
}
.hamburger--squeeze .hamburger-inner::after {
  transition:
    bottom 0.1s 0.14s ease,
    transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition:
    top 0.1s ease,
    opacity 0.1s 0.14s ease;
}
.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition:
    bottom 0.1s ease,
    transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
}
</style>
