export default [
  {
    title: 'global.menu.events',
    route: 'events',
    children: [],
  },
  {
    title: 'global.menu.education',
    route: 'education',
    children: [
      { title: 'global.menu.education-adults', route: 'education-adults' },
      { title: 'global.menu.education-kids', route: 'education-kids' },
      { title: 'global.menu.education-schools', route: 'education-schools' },
      { title: 'global.menu.education-teachers', route: 'education-teachers' },
    ],
  },
  {
    title: 'global.menu.about',
    route: 'about',
    children: [],
    /*
    children: [
      { title: 'global.menu.buildings', route: 'about-buildings' },
      { title: 'global.menu.admission', route: 'about-admission' },
      { title: 'global.menu.reasons', route: 'about-reasons' },
      { title: 'global.menu.collections', route: 'about-collections' },
      { title: 'global.menu.funds', route: 'about-funds' },
      { title: 'global.menu.scientific', route: 'about-scientific' },
      { title: 'global.menu.people', route: 'about-people' },
      { title: 'global.menu.support', route: 'about-support' },
      { title: 'global.menu.rent', route: 'about-rent' },
      { title: 'global.menu.history', route: 'about-history' },
      { title: 'global.menu.media', route: 'about-media' },
      { title: 'global.menu.contact', route: 'about-contact' },
      { title: 'global.menu.news', route: 'about-news' },
    ],
    */
  },
];
