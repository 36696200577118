<script setup>
const localePath = useLocalePath();
</script>

<template>
  <section>
    <div class="club-cta">
      <div class="club-cta__card">
        <picture>
          <source :srcset="useGetImageUrl('/img/karta.png', 160, null, 'webp')" type="image/webp" />

          <img :src="useGetImageUrl('/img/karta.png', 160, null, 'jpg')" :alt="$t('pages.index.club-card-alt')" class="club-cta__card-image" loading="lazy" />
        </picture>
      </div>
      <div class="club-cta__main">
        <div class="club-cta__text" v-html="$t('pages.index.club-cta.text')" />
        <div class="club-cta__button">
          <nuxt-link :to="localePath({ name: 'club' })" class="button button--tumbleweed">{{ $t('pages.index.club-cta.more') }}</nuxt-link>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.club-cta {
  padding: 20px;
  border: 1px solid $black;

  @include mq($lg) {
    display: flex;
    padding: 32px;
  }

  &__card {
  }

  &__card-image {
    width: 160px;
  }

  &__main {
    margin-top: 10px;

    @include mq($lg) {
      margin-top: 0;
      padding-left: 32px;
    }
  }

  &__text {
    font-size: 20px;
    letter-spacing: -0.5px;
    line-height: 1.4;
    font-weight: $font-weight-medium;
  }

  &__button {
    margin-top: 20px;
  }
}
</style>
