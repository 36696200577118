<script setup>
const store = useCommonStore();

useState('hp-transitioning', () => false);

const isMenuOpen = computed(() => store.mobileMenuOpen);
const globalWarningVisible = computed(() => store.globalWarningVisible);

import menuData from '~/assets/data/sidebar-menu';

const route = useRoute();
const { locale, t } = useI18n();

const isHomepage = computed(() => {
  return route.name?.startsWith('index');
});

const menuItems = ref(menuData);

//const development = process.env.NODE_ENV === 'development';

const footerNavItems = computed(() => {
  return [
    {
      title: t('global.menu.buildings'),
      route: { name: 'about-buildings' },
    },
    {
      title: t('global.menu.career'),
      route: { name: 'about-page-slug', params: { slug: locale.value === 'cs' ? 'kariera' : 'job-offers' } },
    },
    {
      title: t('global.menu.admission'),
      route: { name: 'about-admission' },
    },
    {
      title: t('global.menu.contact'),
      route: { name: 'about-contact' },
    },
    {
      title: t('global.menu.support'),
      route: { name: 'about-support' },
    },
  ];
});

const forbiddenFooter = () => {
  const currentRoute = route.name;
  const forbiddenRoutes = ['club', 'club-buy', 'club-thank-you-online', 'about-support', 'about-support-corporate', 'about-support-patrons', 'about-support-private'];

  return !forbiddenRoutes.some((path) => currentRoute.startsWith(path));
};

const pageData = gql`
  query menuItems {
    eventCategories {
      id
      name
      slug {
        cs
        en
      }
    }
    tags(filterVisibleInFilters: true) {
      id
      name
      slug {
        cs
        en
      }
    }
    page(id: 4) {
      children {
        name
        route_id
        external_link
        slug: seo_url_slug {
          cs
          en
        }
      }
    }
    globalWarning {
      global
    }
  }
`;

const { data } = await useAsyncQuery(pageData);

const about = ref(data.value.page);
const tag = ref(data.value.tags);
const event = ref(data.value.eventCategories);
const globalWarning = ref(data.value.globalWarning);

const menuEvents = ref([]);

menuEvents.value.push({
  title: t('pages.events.exhibitions-all-title'),
  route: {
    name: 'events-category',
    params: { category: useSlugify(t('pages.events.exhibitions-all-title')) },
  },
});

menuEvents.value.push({
  title: t('pages.events.expositions-title'),
  route: {
    name: 'events-category',
    params: { category: useSlugify(t('pages.events.expositions-title')) },
  },
});

event.value.forEach((item) => {
  menuEvents.value.push({
    title: item.name,
    route: {
      name: 'events-category',
      params: { category: item.slug[locale.value] },
    },
  });
});

tag.value.forEach((item) => {
  menuEvents.value.push({
    title: item.name,
    route: {
      name: 'events-category',
      params: { category: item.slug[locale.value] },
    },
  });
});
if (locale.value === 'cs') {
  menuEvents.value.push({
    title: 'podcast',
    route: 'events-podcast',
  });
}

menuItems.value.find((x) => x.route === 'events').children = menuEvents.value;

const menuAbout = ref([]);
about.value.children.map((item) => {
  if (item.route_id) {
    menuAbout.value.push({
      title: item.name,
      route: useNoTemplating(item.route_id),
    });
  } else {
    menuAbout.value.push({
      title: item.name,
      route: {
        name: 'about-page-slug',
        params: {
          slug: item.slug[locale.value],
        },
      },
    });
  }
});

menuItems.value.find((x) => x.route === 'about').children = menuAbout.value;

onMounted(() => {
  if (process.browser) {
    const recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute('src', 'https://partners.goout.net/cz-prague/ngpraguecz_new.js');
    document.body.appendChild(recaptchaScript);
  }
});
</script>

<template>
  <div
    class="wrapper"
    :class="{
      'has-warning': globalWarningVisible,
      'has-no-overflow': isMenuOpen,
    }"
  >
    <GlobalWarning v-if="globalWarning && globalWarning.global" :text="globalWarning.global" />
    <MobileHeader :isHomepage="isHomepage" :large="isHomepage" />
    <MobileMenu :items="menuItems" :isHomepage="isHomepage" />
    <div :class="{ 'sidebar--wide': isHomepage }" class="sidebar" role="navigation">
      <SkipToContent />
      <SidebarTopLogo :isHomepage="isHomepage" />
      <TopMenu :isHomepage="isHomepage" />
      <SideMenu :items="menuItems" :isHomepage="isHomepage" />
      <ClientOnly>
        <SidebarBg />
      </ClientOnly>
      <SidebarLogo :large="isHomepage" />
    </div>
    <div id="content" class="content">
      <div class="content__inn">
        <SearchOverlay />
        <div class="content-nuxt">
          <slot />
        </div>

        <Footer v-if="forbiddenFooter()" :nav="footerNavItems" />
      </div>
    </div>
  </div>
</template>
