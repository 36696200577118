<script setup>
const store = useCommonStore();
const isMobileMenuOpen = computed(() => store.mobileMenuOpen);
const { toggleMobileMenu, toggleSearch } = store;

const { locales, locale, t } = useI18n();
const gtm = useGtm();
const localePath = useLocalePath();

const trackClub = (e) => {
  gtm.trackEvent({ event: 'menuClick_clubNGP', url: e.target.href.replace(window.location.origin, '') });
};
const track = (item, e) => {
  gtm.trackEvent({ event: 'menuClick_' + item, url: e.target.href.replace(window.location.origin, '') });
};

const props = defineProps(['items', 'isHomepage']);

const clickAndToggle = (key) => {
  onLinkClick(key);
  toggleMobileMenu();
};

const accordion = ref(null);
const onLinkClick = (key) => {
  accordion.value === key ? (accordion.value = null) : (accordion.value = key);
};
</script>

<template>
  <div class="mobile-menu" :class="{ 'is-active': isMobileMenuOpen }">
    <div class="mobile-menu__header">
      <nuxt-link :to="localePath('index')" class="mobile-menu__logo" @click.native="toggleMobileMenu" @keyup.enter="toggleMobileMenu">
        <img src="/img/logo-white.svg" :alt="$t('global.company-full-name')" class="mobile-menu__logo-elem" />
      </nuxt-link>
      <div class="mobile-menu__langs">
        <a v-for="locale in locales" :key="locale.code" :href="switchLocalePath(locale.code)" class="mobile-menu__lang">{{ locale.name }}</a>
      </div>
      <div class="mobile-menu__search" :aria-label="$t('global.open-search-label')" @click="toggleSearch" @keyup.enter="toggleSearch" />
      <button class="mobile-menu__close">
        <span class="hidden">{{ $t('global.close-button') }}</span>
        <span class="mobile-menu__close-box" :aria-label="$t('global.mobile-nav-close-button')" @click="toggleMobileMenu" @keyup.enter="toggleMobileMenu" />
      </button>
    </div>
    <div class="mobile-menu__main">
      <nav class="mobile-menu__nav">
        <div v-for="(item, key) in items" :key="key" class="mobile-menu__link-wrapper">
          <nuxt-link :to="localePath(item.route)" class="mobile-menu__link" @click.native="clickAndToggle(key)" @keyup.enter="clickAndToggle(key)"> {{ $t(item.title) }}</nuxt-link>
          <button
            class="mobile-menu__arrow"
            v-if="item.children && item.children.length !== 0"
            :class="{ 'is-expanded': accordion === key }"
            @click.native="onLinkClick(key)"
            @keyup.enter="onLinkClick(key)"
          >
            <i class="icon-arrow-left" />
            <span class="hidden">Toggle</span>
          </button>

          <div v-if="item.children" :class="{ 'is-not-shown': accordion !== key }" class="mobile-menu__subnav">
            <div v-for="(subitem, subitemKey) in item.children" :key="subitemKey" class="mobile-menu__subnav-item">
              <!-- if external route-->
              <a v-if="subitem.external_route" :href="subitem.route" class="mobile-menu__subnav-link" target="_blank">
                {{ $t(subitem.title) }}
              </a>

              <!-- vystavy a akce-->
              <nuxt-link
                v-else-if="subitem.route.name == 'events-category'"
                :to="localePath(subitem.route)"
                class="side-menu__subnav-link"
                @click.native="clickAndToggle(subitemKey)"
                @keup.enter="clickAndToggle(subitemKey)"
              >
                {{ subitem.title }}
              </nuxt-link>

              <!-- education -->
              <nuxt-link
                v-else-if="subitem.title.startsWith('global.menu.education')"
                :to="localePath(subitem.route)"
                class="mobile-menu__subnav-link"
                @click.native="clickAndToggle(subitemKey)"
                @keup.enter="clickAndToggle(subitemKey)"
              >
                {{ $t(subitem.title) }}
              </nuxt-link>

              <!-- /o-nas/stranka/[slug] -->
              <nuxt-link
                v-else-if="subitem.route.name"
                :to="
                  localePath({
                    name: 'about-page-slug',
                    params: { slug: subitem.route.params.slug },
                  })
                "
                class="mobile-menu__subnav-link"
                @click.native="toggleMobileMenu"
                @keup.enter="toggleMobileMenu"
              >
                {{ subitem.title }}
              </nuxt-link>
              <nuxt-link v-else :to="localePath(subitem.route)" class="mobile-menu__subnav-link" @click.native="clickAndToggle(subitemKey)" @keup.enter="clickAndToggle(subitemKey)">
                {{ subitem.title }}
              </nuxt-link>
            </div>
          </div>
        </div>
      </nav>
      <div class="mobile-menu__footer">
        <nuxt-link :to="localePath('club')" @click="trackClub($event)" class="mobile-menu__footer-link">{{ $t('pages.club.index.title') }}</nuxt-link>
        <a href="https://shop.ngprague.cz/" target="_blank" @click="track('shop', $event)" class="mobile-menu__footer-link">{{ $t('pages.shop.title') }}</a>
        <div class="mobile-menu__social-links">
          <a href="https://www.facebook.com/NGPrague" target="_blank" class="mobile-menu__social-link">
            <i class="icon-facebook" />
            <span class="hidden">Facebook</span>
          </a>
          <a href="https://www.instagram.com/ngprague/" target="_blank" class="mobile-menu__social-link">
            <i class="icon-instagram" />
            <span class="hidden">Instagram</span>
          </a>
          <a href="https://www.youtube.com/channel/UCeYULpYNcpUJxSeK2FpDpsg" target="_blank" class="mobile-menu__social-link">
            <i class="icon-youtube" />
            <span class="hidden">YouTube</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mobile-menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  padding: 20px 0;
  background: $black;
  color: $white;
  transform: translateX(-100%);
  transition: transform 0.5s $ease-out-quad;

  > * {
    padding-left: 20px;
    padding-right: 20px;
  }

  &.is-active {
    transform: none;
  }

  @include mq($md) {
    padding: 25px 10px;
  }

  @include mq($lg) {
    display: none;
  }

  &__header {
    display: flex;
  }

  &__main {
    display: flex;
    flex-direction: column;
    overflow: hidden auto;
    flex: 1;
    margin-top: 40px;
  }

  &__logo {
  }

  &__logo-elem {
    height: 70px;
  }

  &__langs {
    margin-left: auto;
    padding-top: 4px;
  }

  &__lang {
    margin-right: 15px;
  }

  &__nav {
    padding-top: 5px;
  }

  &__search {
    width: 25px;
    height: 25px;
    margin-right: 15px;
    background: url('/img/ico-search-white.svg') center no-repeat;
    background-size: 18px;
    cursor: pointer;
    transition:
      transform 0.15s,
      filter 0.5s;

    &:hover {
      transform: scale(1.1);
    }
  }

  &__close {
    @include button-reset;
    width: 20px;
    height: 22px;
    padding-top: 3px;
  }

  &__close-box {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2px;
      height: 100%;
      background: $white;
      content: '';
    }

    &:before {
      transform: translateY(-50%) rotate(45deg);
    }

    &:after {
      transform: translateY(-50%) rotate(-45deg);
    }
  }

  &__link-wrapper {
    position: relative;
    margin-bottom: 30px;
    //overflow: hidden;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__arrow {
    @include button-reset;
    position: absolute;
    top: 1em;
    right: 0;
    width: 26px;
    height: 26px;
    transform: translateY(-50%);

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      transform: translate(-50%, -50%) rotate(-90deg);
      transition: transform 0.2s;
    }

    &.is-expanded {
      i {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }

  &__link {
    @include button-reset;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
  }

  &__subnav {
    padding: 30px 0;
    overflow: hidden;
    transition-duration: 350ms;
    &.is-not-shown {
      height: 0;
      padding: 0;
    }
  }

  &__subnav-item {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__subnav-link {
    font-size: 14px;
    text-transform: uppercase;

    &.nuxt-link-active {
      font-weight: $font-weight-medium;
    }
  }

  &__footer {
    display: flex;
    margin-top: auto;
    align-items: center;
  }

  &__footer-link {
    margin-right: 15px;
    font-size: 12px;
    text-transform: uppercase;
  }

  &__social-links {
    display: flex;
    margin-left: auto;
  }

  &__social-link {
    margin-left: 12px;
    font-size: 28px;
  }
}

.side-menu__subnav-link {
  text-transform: uppercase;
  font-size: 14px;
}
</style>
