<script setup>
const props = defineProps(['text']);

import { useCommonStore } from '../stores/common';
const store = useCommonStore();
const isVisible = computed(() => store.globalWarningVisible);
const { toggleGlobalWarning } = store;

const formattedText = JSON.parse(props.text).blocks[0].data.text;

onMounted(() => {
  toggleGlobalWarning();
});

const close = () => {
  toggleGlobalWarning();
};
</script>

<template>
  <div class="global-warning" :class="{ 'is-visible': isVisible }" role="alert" aria-label="Global warning alert">
    <div class="global-warning__text">
      <!-- <DynamicPageContent :content="JSON.parse(props.text).blocks" /> -->
      {{ formattedText }}
    </div>
    <button class="global-warning__close" :aria-label="$t('global.global-warning-close-aria-label')" @click="close" @keyup.enter="close">
      <span class="icon-cross" />
      <span class="hidden">{{ $t('global.close-button') }}</span>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.global-warning {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  min-height: $global-warning-height;
  padding: 15px;
  padding-right: 50px;
  background: $sienna;
  color: $white;
  z-index: 11;
  transform: translateY(-100%);
  transition: transform 0.2s;
  line-height: 1.3;

  @include mq($md) {
    padding-right: 80px;
    text-align: center;
  }

  &.is-visible {
    transform: none;
  }

  &__close {
    @include button-reset;
    position: absolute;
    top: 50%;
    right: 20px;
    padding: 5px;
    transform: translateY(-50%);
    cursor: pointer;

    @include mq($md) {
      right: 50px;
    }
  }

  &__text {
    align-items: center;
    display: flex;
    padding-right: 15px;

    .link {
      margin-left: 15px;
    }
  }
}
</style>
