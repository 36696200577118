<script setup>
const props = defineProps(['items', 'isHomepage']);

const { t } = useI18n();
const route = useRoute();
const gtm = useGtm();
const localePath = useLocalePath();

const store = useCommonStore();
const eventsStore = useEventsStore();

const variant = ref(null);
watch(store, (s) => {
  variant.value = s.getTypeVariant;
});

const activeTabItem = computed(() => eventsStore.activeTabItem);

const track = (item) => {
  gtm.trackEvent({ event: 'menuClick_' + item, url: window.location.pathname });
};

const isActiveLink = (item) => {
  //for events
  if (activeTabItem.value != null && route.path.includes(useSlugify(t('global.menu.events')))) {
    if (item.title == activeTabItem?.value?.name) {
      return true;
    }
  }
  //for about pages
  if (item.route?.params?.slug) {
    return route.fullPath.includes(item.route.params.slug);
  }

  //for education deeper levels
  if (item.title.startsWith('global.menu.education')) {
    return route.fullPath.includes(useSlugify(t(item.title)));
  }

  const childRoute = item.route ? (item.route.params ? item.route.params.slug : item.route) : null;
  if (childRoute != null) {
    const result = childRoute.split('-');
    return route.name.includes(result[result.length - 1]);
  } else {
    return false;
  }
};
</script>

<template>
  <nav
    :class="{
      'side-menu--black': !isHomepage,
      [`side-menu--${variant || 'black'}`]: isHomepage,
      'side-menu__is-homepage': isHomepage,
    }"
    class="side-menu"
  >
    <div v-for="(item, key) in items" :key="key" class="side-menu__link-wrapper">
      <nuxt-link :to="localePath(item.route)" @click="track(item.route)" class="side-menu__link" :class="{ 'nuxt-link-active': isActiveLink(item) }"> {{ $t(item.title) }}</nuxt-link>
      <div v-if="item.children" class="side-menu__subnav side-menu__events-active" :class="{ 'subnav-visible': isActiveLink(item) }">
        <div v-for="(subitem, subitemKey) in item.children" :key="subitemKey" class="side-menu__subnav-item">
          <!-- if external route-->
          <a v-if="subitem.external_route" :href="subitem.route" class="side-menu__subnav-link" target="_blank">
            {{ $t(subitem.title) }}
          </a>
          <!-- sekce vystavy a akce-->
          <nuxt-link
            v-else-if="subitem.route.name == 'events-category'"
            :to="localePath(subitem.route)"
            class="side-menu__subnav-link"
            :class="{
              'nuxt-link-active': isActiveLink(subitem),
            }"
          >
            {{ subitem.title }}
            <!-- {{ subitem }} -->
          </nuxt-link>

          <!-- sekce vzdelavaci programy-->
          <nuxt-link
            v-else-if="subitem.title.startsWith('global.menu.education')"
            :to="localePath(subitem.route)"
            class="side-menu__subnav-link"
            :class="{
              'nuxt-link-active': isActiveLink(subitem),
            }"
          >
            {{ $t(subitem.title) }}
            <!-- {{ subitem }} -->
          </nuxt-link>

          <!-- sekce o nas - generovane pages ze slugu-->
          <nuxt-link
            v-else-if="subitem.route.name"
            :to="
              localePath({
                name: 'about-page-slug',
                params: { slug: subitem.route.params.slug },
              })
            "
            class="side-menu__subnav-link"
            :class="{
              'nuxt-link-active': isActiveLink(subitem),
            }"
          >
            {{ subitem.title }}
            <!-- {{ subitem }} -->
          </nuxt-link>

          <!-- sekce o nas - vsechny -->
          <nuxt-link
            v-else
            :to="localePath(subitem.route)"
            class="side-menu__subnav-link"
            :class="{
              'nuxt-link-active': isActiveLink(subitem),
            }"
          >
            {{ subitem.title }}
            <!-- {{ subitem }} -->
          </nuxt-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.side-menu {
  $s: &;
  margin-bottom: 25px;
  transition: color 0.5s;
  z-index: 5;

  &--white,
  &--border {
    color: $white;
  }

  &__link-wrapper {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    @include underline-transition(1px);
    font-size: 20px;
    letter-spacing: -0.25px;
    text-transform: uppercase;
    text-decoration: none;

    &.nuxt-link-active {
      font-weight: $font-weight-medium;
    }
  }

  &__subnav {
    overflow: hidden;
    max-height: 0;
    transition:
      max-height 0.3s $ease-out-quad,
      visibility 0.01s 0.3s;
    visibility: hidden;

    &.subnav-visible {
      visibility: visible;
      max-height: 600px;
      // overflow: auto;
      transition: max-height 0.3s 0.6s $ease-in-quad;
    }
  }

  &__subnav-item {
    margin-bottom: 10px;
    font-size: 12px;
    text-transform: uppercase;

    &:first-child {
      margin-top: 15px;
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 2px;
    }
  }

  &__subnav-link {
    @include underline-transition(1px);

    &.nuxt-link-active {
      font-weight: $font-weight-medium;
    }

    &.is-exact {
      &.nuxt-link-active {
        font-weight: $font-weight-normal;
      }

      &.nuxt-link-exact-active {
        font-weight: $font-weight-medium;
      }
    }
  }

  &__events-active {
    #{$s}__subnav-link {
      &.is-exact {
        &.nuxt-link-exact-active {
          font-weight: $font-weight-normal;
        }

        &.nuxt-link-event-active {
          font-weight: $font-weight-medium;
        }
      }
    }
  }
}
</style>
