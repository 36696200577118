<script setup>
import { useCommonStore } from '../stores/common';
// TODO: Animation should not be a drawer but a fade transition to black similar to the Homepage to Event page transition
import SearchResult from './SearchResult.vue';

const term = ref('');
const searchedTerm = ref('');
const searchResults = ref([]);
const filterSearchDelayMs = ref(40); // ms

const searchError = ref(false);

const store = useCommonStore();
const searchVisible = computed(() => store.searchVisible);
const { toggleSearch } = store;

const route = useRoute();
const gtm = useGtm();
const { locale, t } = useI18n();

let filterSearchTimer = 200;

const input = ref(null);

watch(searchVisible, (value) => {
  const overflow = value ? 'hidden' : '';
  document.body.style.overflow = overflow;
  if (value) {
    setTimeout(input.value.focus());
  }
});

watch(route, () => {
  toggleSearch;
});

onMounted(() => {
  addEventListener('keyup', ({ key }) => {
    if (key === 'Escape') {
      toggleSearch;
    }
  });
});

const filterSearchDelay = () => {
  clearTimeout(filterSearchTimer);
  filterSearchTimer = setTimeout(() => {
    checkSearch();
  }, filterSearchDelayMs.value);
  searchError.value = false;
};

const checkSearch = async () => {
  if (term.value.length >= 3) {
    const url = 'https://customsearch.googleapis.com/customsearch/v1?cx=9b8b2bf44773a75d3&key=AIzaSyDm4HgXBiTu21QyBJgVVi_S2L4_KFWPE0k&lr=lang_' + locale.value + '&q=' + term.value;
    const { data, error } = await useFetch(url);

    if (error.value) {
      console.log('error', error.value);
      searchError.value = true;
    } else {
      searchedTerm.value = term;
      if (data.value?.items) {
        if (locale === 'cs') {
          searchResults.value = data.value.items.filter((result) => !result.link.includes('https://www.ngprague.cz/en/'));
        } else {
          searchResults.value = data.value.items;
        }
      } else {
        searchResults.value = [];
      }
    }
    gtm.trackEvent({ event: 'searchUsed', term: searchedTerm.value, url: window.location.href.replace(window.location.origin, '') });
  }
};
</script>

<template>
  <div role="search" class="search" :class="{ 'is-active': searchVisible }" :aria-hidden="!searchVisible">
    <button class="search__close" @click="toggleSearch" @keyup.enter="toggleSearch">
      <span>{{ t('global.close-button') }}</span> <i class="icon-cross" />
    </button>
    <div class="search__input">
      <input id="text" ref="input" v-model.trim="term" type="text" class="input input--full input--site-search" @input="filterSearchDelay" autocomplete="off" />
    </div>
    <h2 v-if="searchedTerm" class="search__info">
      {{ t('search.results-title') }}
      <span class="fwm">{{ searchedTerm }}</span>
    </h2>
    <div class="search__results">
      <template v-if="searchResults.length > 0">
        <SearchResult v-for="result in searchResults" :key="result.cacheId" :title="result.title" :url="result.link" :body="result.htmlSnippet" />
      </template>

      <template v-else>
        <p v-if="searchedTerm" class="heading heading--2">{{ t('global.search.term-not-found') }}</p>
      </template>
      <p v-if="searchError">{{ t('global.error.unknown-error') }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search {
  position: fixed;
  z-index: 101;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 50px 25px;
  background: $black;
  color: $white;
  transform: translateX(100%);
  transition:
    transform 0.5s $ease-out-quad,
    visibility 0.01s 0.5s;
  visibility: hidden;

  @include mq($lg) {
    left: $sidebar-width;
    width: calc(100% - #{$sidebar-width});
    padding: 100px 75px;
  }
  .page-index & {
    @include mq($lg) {
      left: 50%;
      width: 50%;
      padding: 100px 40px;
    }

    @include mq($xl) {
      left: 60%;
      width: 40%;
      padding: 100px 50px;
    }
  }

  &.is-active {
    transform: none;
    visibility: visible;
    transition: transform 0.5s $ease-out-quad;
  }

  &__close {
    @include button-reset;
    position: absolute;
    top: 25px;
    right: 25px;
    color: $white;
    font-size: 12px;
    text-transform: uppercase;
    cursor: pointer;

    @include mq($lg) {
      top: 45px;
      right: 75px;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }

    i {
      vertical-align: middle;
      font-size: 1.3em;
    }
  }

  &__info {
    margin-top: 30px;
  }

  &__results {
    margin-top: 40px;
  }
}
</style>
