<script setup>
const store = useCommonStore();
</script>

<template>
  <div class="sidebar-bg">
    <div class="sidebar-bg__image-wrapper">
      <div v-for="(image, index) in store.images" :key="index + image" class="sidebar-bg__image" :class="{ 'is-active': index == store.activeItem }">
        <picture>
          <img :src="useGetImageUrl(image, 1200, 1080)" />
        </picture>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sidebar-bg {
  $s: &;
  @include overlay;
  user-select: none;
  background-color: $white;
  overflow: hidden;
  z-index: 1;

  &__image-wrapper {
    z-index: 2;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateZ(0);
  }

  &__image {
    @include overlay;
    z-index: 1;
    opacity: 0;
    transform: scale(1.2);
    transition:
      opacity 0ms 300ms,
      transform 0ms 300ms;

    img {
      display: block;
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &.is-active {
      opacity: 1;
      transform: scale(1);
      transition:
        opacity 300ms,
        transform 300ms;
      z-index: 2;
    }
  }
}
</style>
