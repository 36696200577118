<script setup>
const props = defineProps(['large']);
const store = useCommonStore();

const variant = ref(null);
watch(store, (s) => {
  variant.value = s.getTypeVariant || 'black';
});

const centerWidth = ref('100%'); // helper for ff & ie :(
const isHidden = ref(false);
const scaleImage = ref(null);

onMounted(() => {
  window.addEventListener('resize', onResize);
  setLogoWidth();
});

onUnmounted(() => {
  window.removeEventListener('resize', onResize);
});

const onResize = useDebounce(() => {
  setLogoWidth();
}, 250);

const setLogoWidth = () => {
  centerWidth.value = scaleImage.value.width + 'px';
};
</script>

<template>
  <div :class="{ 'sidebar-logo--large': props.large, 'is-hidden': isHidden }" class="sidebar-logo">
    <div class="sidebar-logo__center">
      <img class="is-active sidebar-logo__image" :src="`/img/logo-${variant}.svg`" draggable="false" alt="logo" ref="scaleImage" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sidebar-logo {
  $s: &;
  flex-grow: 1;
  margin-right: -10px;
  margin-top: auto;
  max-height: 200px;
  pointer-events: none;
  overflow: hidden;
  position: relative;
  transition: height 0.2s;
  z-index: 2;

  &__center {
    display: inline-block;
    height: 100%;
    left: 0;
    //right: 125px;
    overflow: hidden;
    top: 0;
    position: absolute;
  }

  &.is-hidden {
    opacity: 0;
    pointer-events: none;
  }

  .has-warning & {
    height: calc(100vh - 340px);
  }

  &__image {
    position: absolute;
    bottom: 0;
    display: block;
    max-height: 100%;
    max-width: 100%;
    width: auto;
    opacity: 0;
    transition:
      opacity 0.6s,
      width 0.5s 0.5s;
    user-select: none;
    pointer-events: none;

    &:first-child {
      position: relative;
    }

    &.is-active {
      opacity: 1;
    }
  }

  &__photo {
    animation: sidebar-photo-appear 0.5s forwards 1;
    bottom: 31%;
    left: 21%;
    height: 40%;
    object-fit: cover;
    object-position: center center;
    position: absolute;
    transform-origin: 0 50%;
    width: 57%;
  }

  &--large {
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    margin-right: 0;

    #{$s}__center {
      display: block;
      max-width: 100% !important;
      width: 100%;
    }

    #{$s}__image {
      height: 100%;
      //object-position: left top;

      &:first-child {
        position: absolute;
        max-width: 100%;
      }
    }

    #{$s}__photo {
      transform: scaleX(0);
    }
  }

  .page-index & {
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    margin-right: 0;

    #{$s}__center {
      display: block;
      max-width: 100% !important;
    }

    #{$s}__image {
      &:first-child {
        position: absolute;
        max-width: 100%;
      }
    }
  }
}
</style>
