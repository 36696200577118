<script setup>
const { locale } = useI18n();

const partnersQuery = gql`
  query partnersQuery {
    partners(onlyVisibleOnHomepage: true) {
      id
      name
      image: image_list
      slug: seo_url_slug {
        cs
        en
      }
      group {
        id
        name
      }
    }
  }
`;

const partnerList = ref({});
const partnersOrganized = ref([]);

const updatepartners = (data) => {
  if (data) {
    data.forEach((x) => {
      const group = x.group.id;
      if (!partnerList.value[group]) {
        partnerList.value[group] = { name: x.group.name, partners: [] };
      }
      partnerList.value[group].partners.push(x);
    });
  }
};

const { data } = await useAsyncQuery(partnersQuery);
const partners = ref(data.value?.partners || []);

onMounted(() => {
  updatepartners(partners.value);
  //fix na vymenu partneru generalni medialni partner a medialni partner
  partnersOrganized.value = partnerList.value[6];
  partnerList.value[6] = partnerList.value[4];
  partnerList.value[4] = partnersOrganized.value;
});
</script>

<template>
  <br /><br />
  <div class="footer-partners">
    <div>
      <div v-for="(group, groupKey) in partnerList" :key="groupKey" cols="12" class="footer-partners__group">
        <div class="footer-partners__title">
          <h3 class="heading heading--5">{{ group?.name }}</h3>
        </div>
        <ul class="footer-partners__logos">
          <li v-for="(partner, partnerKey) in group?.partners" :key="partnerKey" class="footer-partners__logo">
            <nuxt-link
              :to="
                localePath({
                  name: 'about-partners-slug',
                  params: { slug: partner.slug[locale] },
                })
              "
            >
              <img
                :src="useGetImageUrl(partner.image, null, 35) || '/img/dummy/ngp.png'"
                :alt="partner.name"
                class="footer-partners__logo-elem"
                :class="'footer-partners__logo-elem--' + partner.slug[locale]"
              />
            </nuxt-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer-partners {
  &__group {
    margin-bottom: 20px;
  }

  &__title {
    margin-bottom: 15px;
    h3 {
      font-size: 14px;
    }
  }

  &__logos {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 10px;
  }

  &__logo {
    align-items: center;
    display: flex;
    height: 50px;
    margin-bottom: 10px;
    margin-right: 20px;
  }

  &__logo-elem {
    // height: 25px;
    min-height: 32px;
    width: 100%;
    max-width: 100%;
    object-fit: contain;

    &--komercni-banka {
      height: auto;
      max-width: 275px;
      width: 100%;
    }

    &--ceska-televize {
      height: 20px;
    }
  }
}
</style>
