<script setup>
const props = defineProps(['nav']);
const gtm = useGtm();

const { locale } = useI18n();
const localePath = useLocalePath();

const store = useCommonStore();
watchEffect(() => {
  document?.documentElement.style.setProperty('--footer-background', store.getPageColor);
});

const track = () => {
  gtm.trackEvent({ event: 'newsletter_visit', url: window.location.pathname });
};
</script>

<template>
  <div class="footer-container" :style="{ 'background-color': 'var(--footer-background)' }">
    <div class="footer visible animate__animated animate__fadeInUp">
      <CTAClub />
      <section class="mt-5">
        <div class="align-items-center">
          <div>
            <div class="social-links">
              <a href="https://www.facebook.com/NGPrague" target="_blank" class="social-links__item">
                <i class="icon-facebook" />
                <span class="hidden">Facebook</span>
              </a>
              <a href="https://www.instagram.com/ngprague/" target="_blank" class="social-links__item">
                <i class="icon-instagram" />
                <span class="hidden">Instagram</span>
              </a>
              <a href="https://www.youtube.com/channel/UCeYULpYNcpUJxSeK2FpDpsg" target="_blank" class="social-links__item">
                <i class="icon-youtube" />
                <span class="hidden">YouTube</span>
              </a>
            </div>
          </div>
          <div class="mt-3">
            <nuxt-link :to="localePath('newsletter')" class="newsletter-link" @click.prevent="track()">{{ $t('pages.index.subscribe-link') }}</nuxt-link>
          </div>
        </div>
      </section>
      <section class="footer__social mt-4">
        <FooterNav :items="nav" />
      </section>
      <section class="mt-5 pb-5">
        <FooterPartners />
        <p class="footer__thank-you" v-html="$t('pages.index.footer.thank-you')"></p>
        <nuxt-link :to="localePath('about-partners')" class="footer__partners-link link">{{ $t('pages.index.link.show-all-partners') }}</nuxt-link>
      </section>
      <img src="/img/logo-alt.svg" alt="NGP" class="footer__logo" loading="lazy" />
      <ul class="mt-5 footer__links">
        <li>
          <nuxt-link
            :to="
              localePath({
                name: 'about-page-slug',
                params: {
                  slug: locale == 'cs' ? 'prohlaseni-o-pristupnosti' : 'accessibility-statement',
                },
              })
            "
            class="link"
          >
            {{ $t('global.footer.accessibility') }}
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            :to="
              localePath({
                name: 'about-page-slug',
                params: {
                  slug: 'gdpr',
                },
              })
            "
            class="link"
          >
            {{ $t('global.footer.privacy') }}
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            :to="
              localePath({
                name: 'about-page-slug',
                params: {
                  slug: 'imprint',
                },
              })
            "
            class="link"
          >
            {{ $t('global.footer.imprint') }}
          </nuxt-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer {
  margin: 0 $main-padding-mobile-x 0;
  padding-bottom: 30px;
  position: relative;
  transition: opacity 300ms 200ms;
  z-index: 2;

  &.is-not-visible {
    opacity: 0 !important;
    pointer-events: none;
    transition: opacity 300ms;
  }

  @include mq($lg) {
    border-bottom: 100px transparent solid;
    margin-left: 40px;
    margin-right: 40px;
  }

  @include mq($xl) {
    margin-left: 80px;
    margin-right: 80px;
  }

  &__logo {
    position: absolute;
    bottom: 30px;
    right: 0px;
    width: 80px;

    @include mq($lg) {
      bottom: 70px;
      right: 40px;
    }
  }

  &__social {
    padding: 20px 0;
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
  }

  &__thank-you {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-right: 100px;
    font-size: 20px;
    line-height: 1.4;
    font-weight: $font-weight-medium;

    @include mq($lg) {
      padding-right: 0;
    }
  }

  &__partners-link {
    display: inline-block;
    padding-top: 22px;
    font-size: 14px;
  }

  &__links {
    li {
      display: block;

      @include mq($sm) {
        display: inline-block;

        &:not(:first-child) {
          margin-left: 10px;
        }
      }
    }

    a {
      font-size: 14px;
    }
  }
}

.main {
  &--narrow {
    + .footer {
      border-bottom-width: 30px;
      margin-bottom: 20px;
      margin-left: 20px;
      margin-right: 20px;

      @include mq($lg) {
        margin-bottom: 0;
        margin-left: 40px;
        margin-right: 40px;
      }
    }
  }
}

.social-links {
  $s: &;

  &__item {
    margin-right: 12px;
    font-size: 32px;

    &:last-child {
      margin-right: 0;
    }
  }

  &--small {
    #{$s}__elem {
      font-size: 28px;
    }
  }
}

.newsletter-link {
  font-size: 14px;
  font-weight: $font-weight-medium;
  text-transform: uppercase;

  @include underline-transition(1px);
}
</style>
